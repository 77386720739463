import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import snakecaseKeys from "snakecase-keys";
import camelcaseKeys from "camelcase-keys";
import { newDateFromDateString, toISODateString } from "../../utils/dates";

import { Navigation, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Tab } from "./Tab";
import parse from "html-react-parser";

interface RestaurantData {
  title: string;
  slug: string;
  restaurantName: string;
  imageUrl: string;
  restaurantCategoryAndPref: string;
  guestCapacity: string;
  availableRsCount: number;
}

type Props = {
  defaultDate: string;
  defaultLastDate: string;
  tabContents: string[];
};

const getLocaleFromPath = () => {
  const path = window.location.pathname;
  if (path.startsWith("/ko")) {
    return "ko";
  } else if (path.startsWith("/zh-cn")) {
    return "zh-Hans";
  } else if (path.startsWith("/zh-tw")) {
    return "zh-Hant";
  }
  return "en"; // デフォルトは英語
};

const getLanguagePrefix = () => {
  const path = window.location.pathname;
  if (path.startsWith("/ko")) {
    return "/ko";
  } else if (path.startsWith("/zh-cn")) {
    return "/zh-cn";
  } else if (path.startsWith("/zh-tw")) {
    return "/zh-tw";
  }
  return "";
};

const InstantReservableRestaurantsList: React.FC<Props> = ({
  defaultDate,
  defaultLastDate,
  tabContents,
}) => {
  const carouselItemSizeForPc = 4;
  const carouselItemSizeForMb = 1;
  const millisecondsPerDay = 86400000;
  const firstDate = newDateFromDateString(defaultDate);
  const lastDate = newDateFromDateString(defaultLastDate);
  const maxDays =
    (lastDate.getTime() - firstDate.getTime()) / millisecondsPerDay + 1;
  const locale = getLocaleFromPath();

  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth / window.innerHeight < 0.75,
  );
  const [listByDateByPrefecture, setListByDateByPrefecture] = useState<{
    [prefecture: string]: { [key: string]: RestaurantData[] };
  }>(
    tabContents.reduce<{
      [prefecture: string]: { [key: string]: RestaurantData[] };
    }>((result, prefecture) => {
      result[prefecture] = Array.from({ length: maxDays }).reduce<{
        [key: string]: RestaurantData[];
      }>((acc, _, idx) => {
        let newDate = newDateFromDateString(defaultDate);
        newDate = new Date(newDate.getTime() + idx * millisecondsPerDay);
        let dateKey = toISODateString(newDate).replace(/-/g, "");
        if (acc[dateKey]) {
          newDate.setDate(newDate.getDate() + 1);
          dateKey = toISODateString(newDate).replace(/-/g, "");
        }
        acc[dateKey] = [
          {
            title: `${newDate.toLocaleDateString(locale, {
              month: "short",
            })} ${newDate.getDate()} ${newDate.toLocaleDateString(locale, { weekday: "short" })}`,
            slug: "",
            restaurantName: "",
            imageUrl: "",
            restaurantCategoryAndPref: "",
            guestCapacity: "",
            availableRsCount: 0,
          },
        ];
        return acc;
      }, {}) as { [key: string]: RestaurantData[] };
      return result;
    }, {}) as { [prefecture: string]: { [key: string]: RestaurantData[] } },
  );
  const [searchedDatesByPrefecture, setSearchedDatesByPrefecture] = useState<{
    [key: string]: Date[];
  }>({});
  const [loadingDates, setLoadingDates] = useState<Date[]>([]);
  const [displayMonthText, setDisplayMonthText] = useState<string>("");
  const [slideCount, setSlideCount] = useState<number>(0);
  const [isDisabledPrevMonthBtn, setIsDisabledPrevMonthBtn] =
    useState<boolean>(true);
  const [isDisabledNextMonthBtn, setIsDisabledNextMonthBtn] =
    useState<boolean>(false);
  const [prefectureKey, setPrefectureKey] = useState<string>(tabContents[0]);

  const lastIndex =
    maxDays - (isMobile ? carouselItemSizeForMb : carouselItemSizeForPc);

  useEffect(() => {
    fetchAvailableRestaurants(prefectureKey);

    const handleResize = () => {
      setIsMobile(window.innerWidth / window.innerHeight < 0.75);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const dateKeys = Object.keys(listByDateByPrefecture[prefectureKey]);
    const setText = (start_position) => {
      if (isMobile) {
        const dateKey = dateKeys[start_position];
        const date = getDateFromDateKey(dateKey);
        setDisplayMonthText(
          Intl.DateTimeFormat(locale, {
            month: "short",
            year: "numeric",
          }).format(date),
        );
      } else {
        const slicedDateKeys = dateKeys.slice(
          start_position,
          start_position + carouselItemSizeForPc,
        );
        const firstDate = getDateFromDateKey(slicedDateKeys[0]);
        const lastDate = getDateFromDateKey(
          slicedDateKeys[slicedDateKeys.length - 1],
        );
        if (firstDate.getMonth() === lastDate.getMonth()) {
          setDisplayMonthText(
            Intl.DateTimeFormat(locale, {
              month: "short",
              year: "numeric",
            }).format(firstDate),
          );
        } else {
          setDisplayMonthText(
            [
              Intl.DateTimeFormat(locale, {
                month: "short",
                year: "numeric",
              }).format(firstDate),
              "-",
              Intl.DateTimeFormat(locale, {
                month: "short",
                year: "numeric",
              }).format(lastDate),
            ].join(" "),
          );
        }
      }
    };

    if (swiperRef.current?.swiper?.isBeginning) {
      setIsDisabledPrevMonthBtn(true);
      setIsDisabledNextMonthBtn(false);
      setText(0);
    } else if (swiperRef.current?.swiper?.isEnd) {
      setIsDisabledPrevMonthBtn(false);
      setIsDisabledNextMonthBtn(true);
      setText(lastIndex);
    } else {
      setIsDisabledPrevMonthBtn(false);
      setIsDisabledNextMonthBtn(false);
      setText(swiperRef.current?.swiper?.activeIndex);
    }
  }, [slideCount, isMobile]);

  const getDateFromDateKey = (dateKey: string) => {
    return new Date(
      dateKey.substring(0, 4),
      parseInt(dateKey.substring(4, 6)) - 1,
      dateKey.substring(6, 8),
    );
  };

  const fetchAvailableRestaurants = async (prefectureKey) => {
    setSlideCount(slideCount + 1);
    const targetDate = newDateFromDateString(defaultDate);
    targetDate.setDate(
      targetDate.getDate() + swiperRef.current?.swiper?.activeIndex,
    );
    if (lastDate < targetDate) {
      targetDate = new Date(lastDate.getTime());
    }
    const targetTime = targetDate.getTime();
    let targetDates = Array.from({ length: carouselItemSizeForPc }, (_, i) => {
      const d = new Date(targetTime + millisecondsPerDay * i);
      return d <= lastDate ? d : lastDate;
    });
    if (isMobile) {
      targetDates = targetDates.concat(
        Array.from({ length: carouselItemSizeForPc }, (_, i) => {
          const d = new Date(targetTime - millisecondsPerDay * i);
          return d >= firstDate ? d : firstDate;
        }).filter((d) => d),
      );
    }

    targetDates = targetDates.reduce((accumulator, date) => {
      if (!accumulator.find((d) => d.getTime() === date.getTime())) {
        accumulator.push(date);
      }
      return accumulator;
    }, []);

    const searchedDates = searchedDatesByPrefecture[prefectureKey] || [];
    const unsearchedDates = targetDates.filter(
      (date) => !searchedDates.some((d) => d.getTime() === date.getTime()),
    );
    if (unsearchedDates.length === 0) return;

    setLoadingDates(
      unsearchedDates.map((d) => toISODateString(d).replace(/-/g, "")),
    );
    const url = `${getLanguagePrefix()}/instant_reservable_restaurants`;
    const formatDate = (date: Date, locale: string) => {
      return `${date.toLocaleDateString(locale, {
        month: "short",
      })} ${date.getDate()} ${date.toLocaleDateString(locale, { weekday: "short" })}`;
    };
    try {
      const response = await axios.get(url, {
        params: snakecaseKeys({
          dates: unsearchedDates.map((date) => toISODateString(date)),
          prefecture_key: prefectureKey,
        }),
      });
      if (response.status === 200) {
        const data = camelcaseKeys(response.data, { deep: true });
        const formattedData = Object.entries(data["listByDate"]).reduce(
          (acc, [key, value]) => {
            const date = new Date(
              key.substring(0, 4),
              parseInt(key.substring(4, 6)) - 1,
              parseInt(key.substring(6, 8)),
            );
            acc[key] = value.map((restaurant) => ({
              ...restaurant,
              title: formatDate(date, locale),
            }));
            return acc;
          },
          {},
        );
        setListByDateByPrefecture((prevState) => ({
          ...prevState,
          [prefectureKey]: {
            ...prevState[prefectureKey],
            ...formattedData,
          },
        }));
        setSearchedDatesByPrefecture((prevState) => ({
          ...prevState,
          [prefectureKey]: [
            ...(prevState[prefectureKey] || []),
            ...unsearchedDates,
          ].sort((a, b) => a - b),
        }));
        setLoadingDates([]);
      } else {
        return Promise.reject(response);
      }
    } catch (error) {
      console.error(error);
      setLoadingDates([]);
      return false;
    }
  };

  const swiperRef = useRef<Swiper>(null);

  const slideToNextMonth = () => {
    if (!swiperRef.current || !swiperRef.current.swiper) return;

    const dateKey = Object.keys(listByDateByPrefecture[prefectureKey])[
      swiperRef.current.swiper.activeIndex +
        (isMobile ? carouselItemSizeForMb : carouselItemSizeForPc) -
        1
    ];
    const displayedLastDate = new Date(
      dateKey.substring(0, 4),
      parseInt(dateKey.substring(4, 6)) - 1,
      dateKey.substring(6, 8),
    );
    displayedLastDate.setMonth(displayedLastDate.getMonth() + 1);
    displayedLastDate.setDate(1);

    let index = parseInt((displayedLastDate - firstDate) / millisecondsPerDay);
    if (index > lastIndex) {
      index = maxDays;
    }
    swiperRef.current.swiper.slideTo(index);
  };

  const slideToPrevMonth = () => {
    if (!swiperRef.current || !swiperRef.current.swiper) return;

    const dateKey = Object.keys(listByDateByPrefecture[prefectureKey])[
      swiperRef.current.swiper.activeIndex
    ];
    const displayedFirstDate = new Date(
      dateKey.substring(0, 4),
      parseInt(dateKey.substring(4, 6)) - 1,
      dateKey.substring(6, 8),
    );

    displayedFirstDate.setMonth(displayedFirstDate.getMonth() - 1);
    displayedFirstDate.setDate(1);

    let index = parseInt((displayedFirstDate - firstDate) / millisecondsPerDay);
    if (index < 0) {
      index = 0;
    }
    swiperRef.current.swiper.slideTo(index);
  };

  return (
    <>
      <Tab
        onChange={(d) => {
          setPrefectureKey(tabContents[d]);
          fetchAvailableRestaurants(tabContents[d]);
        }}
        tabContents={tabContents}
      />
      <div className="d-flex justify-content-between mb-3 mt-3" role="group">
        <button
          className="btn btn-light"
          onClick={() => slideToPrevMonth()}
          disabled={isDisabledPrevMonthBtn}
          aria-label="Prev"
        >
          <i className="fas fa-chevron-left"></i>
        </button>
        <div className="fs-5 fw-bold">{displayMonthText}</div>
        <button
          className="btn btn-light"
          onClick={() => slideToNextMonth()}
          disabled={isDisabledNextMonthBtn}
          aria-label="Next"
        >
          <i className="fas fa-chevron-right"></i>
        </button>
      </div>
      <Swiper
        ref={swiperRef}
        // install Swiper modules
        modules={[Navigation, A11y]}
        spaceBetween={15}
        slidesPerView={isMobile ? carouselItemSizeForMb : carouselItemSizeForPc}
        slidesPerGroup={
          isMobile ? carouselItemSizeForMb : carouselItemSizeForPc
        }
        onSlideChange={() => fetchAvailableRestaurants(prefectureKey)}
        navigation
        className="p-topIrs"
      >
        {Object.keys(listByDateByPrefecture[prefectureKey]).map(
          (dateKey, index) => {
            const restaurants = listByDateByPrefecture[prefectureKey][dateKey];
            const slug = restaurants[0]["slug"];
            return (
              <SwiperSlide
                key={`${dateKey}-${slug}-${index}`}
                className="p-topIrs_item"
              >
                <div className="p-topIrs_date">
                  <div className="p-topIrs_date_num">
                    {restaurants[0]["title"]}
                  </div>
                </div>
                <div className="p-topIrs_date_content">
                  {loadingDates.includes(dateKey) ? (
                    <Loading />
                  ) : (
                    <SwiperSlideContents
                      restaurants={restaurants}
                      key={`${slug}-${index}`}
                    />
                  )}
                </div>
              </SwiperSlide>
            );
          },
        )}
      </Swiper>
    </>
  );
};

const Loading = () => {
  return (
    <div className="p-topIrs_resItem placeholder-glow">
      <span className="placeholder bg-primary col-12 w-50"></span>
      <span className="placeholder bg-primary col-12 w-100"></span>
      <span className="placeholder bg-primary col-12 w-100"></span>
    </div>
  );
};

const SwiperSlideContents: React.FC<{ restaurants: RestaurantData[] }> = ({
  restaurants,
}) => {
  // restaurantsが存在しない、もしくはrestaurantNameが全て存在しない場合はコンテンツを表示しない
  if (
    restaurants.length === 0 ||
    restaurants.every((restaurant) => !restaurant.restaurantName)
  ) {
    return (
      <div className="p-topIrs_empty">
        {parse(
          window.i18n.t(
            "components.home.instant_reservable_restaurants_list.top_irs_empty",
          ),
        )}
      </div>
    );
  }

  return (
    <>
      {restaurants.map((restaurant, index) => (
        <Item
          restaurantInfo={restaurant}
          key={`item-${restaurant.slug}-${index}`}
        />
      ))}
    </>
  );
};

const Item: React.FC<{ restaurantInfo: RestaurantData }> = ({
  restaurantInfo,
}) => {
  if (!restaurantInfo.restaurantName) {
    return null;
  }

  const isAvailableRs = restaurantInfo.availableRsCount > 0;

  const generateImageTag = () => {
    return (
      <div className="p-topIrs_resItem_img">
        <div
          style={{
            backgroundImage: `url(${restaurantInfo.imageUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
      </div>
    );
  };
  const generateContentTag = () => {
    return (
      <div className="p-topIrs_content">
        <div className="p-topIrs_resItem_title">
          {restaurantInfo.restaurantName}
        </div>
        <div className="p-topIrs_resItem_info">
          {restaurantInfo.restaurantCategoryAndPref}
        </div>
        <div className="p-topIrs_resItem_gc">
          {restaurantInfo.guestCapacity}
        </div>
      </div>
    );
  };

  const languagePrefix = getLanguagePrefix();

  if (isAvailableRs) {
    return (
      <div key={restaurantInfo.slug} className={`p-topIrs_resItem`}>
        <a
          href={`${languagePrefix}/restaurants/${restaurantInfo.slug}`}
          key={restaurantInfo.slug}
        >
          <div className="p-topIrs_resItem_wrap">
            {generateImageTag()}
            {generateContentTag()}
          </div>
        </a>
      </div>
    );
  } else {
    return (
      <div
        key={restaurantInfo.slug}
        className={`p-topIrs_resItem p-topIrs_resItem-full`}
      >
        <div className="p-topIrs_resItem_wrap">
          {generateImageTag()}
          {generateContentTag()}
        </div>
      </div>
    );
  }
};

export default InstantReservableRestaurantsList;

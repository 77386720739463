import React from "react";
import axios from "axios";
import Rails from "rails-ujs";

interface OpenReceiptButtonProps {
  stripePaymentIntentId: string;
}

const OpenReceiptButton: React.FC<OpenReceiptButtonProps> = ({
  stripePaymentIntentId,
}) => {
  const handleReceiptRequest = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "/stripe/receipt",
        {
          payment_intent_id: stripePaymentIntentId,
        },
        {
          headers: {
            Accept: "application/json",
            "X-CSRF-Token": Rails.csrfToken(),
          },
        },
      );
      if (response.data && response.data.receipt_url) {
        window.open(response.data.receipt_url, "_blank");
      } else {
        console.error(response.data.message);
        alert(response.data.message);
      }
    } catch (error) {
      console.error(error.response.data.error);
    }
  };

  return (
    <div>
      <button
        className="btn btn-sm btn-outline-primary"
        type="button"
        onClick={handleReceiptRequest}
      >
        {window.i18n.t(
          "components.user.reservations.open_receipt_button.show_receipt",
        )}
      </button>
    </div>
  );
};

export default OpenReceiptButton;
